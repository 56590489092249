import React from 'react';
import HtmlContent from './HtmlContent';
import styles from './ImageObject.module.css';

const ImageObject = ({ metadata: { name, image } }) => (
  <HtmlContent name={name} leftWidthRatio={0.65}>
    <img className={styles.image} src={ image } alt={ name } />
  </HtmlContent>
)

export default ImageObject;
