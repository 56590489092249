import React from 'react'
import { withHtmlContent } from '../withContent'
import Paper from '../components/Paper'
import styles from './HtmlContent.module.css'
import { useMeasure } from 'react-use'

const makeStyle = width => (
  {style: {maxWidth: width, flexBasis: width}}
)

const makeWidth = (containerWidth, widthRatio) => (
  containerWidth > 1280
  ?
  `${widthRatio * 100}%`
  :
  "100%"
)

const HtmlContent = ({ name, leftWidthRatio = 0.5, content, children }) => {
  const [containerRef, { width }] = useMeasure()
  return <>
      <h2 className={styles.title}>
        { name }
      </h2>
      <div className={styles.container} ref={containerRef}>
        <div className={styles.itemLeft} {...makeStyle(makeWidth(width, leftWidthRatio))}>
          {children}
        </div>
        <div className={styles.itemRight} {...makeStyle(makeWidth(width, 1 - leftWidthRatio))}>
          <Paper dangerouslySetInnerHTML={{ __html: content }}/>
        </div>
      </div>
  </>
}

export default withHtmlContent(HtmlContent)
