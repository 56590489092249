import React from 'react';
import ReactPlayer from 'react-player'
import HtmlContent from './HtmlContent';
import styles from './VideoObject.module.css';

const VideoObject = ({ metadata: { name, embedUrl, "nf:aspectRatio": aspectRatio } }) => (
  <HtmlContent name={name} leftWidthRatio={0.65}>
    <div style={{aspectRatio: aspectRatio || "16 / 9"}}>
      {
        embedUrl.includes("drive.google.com")
        ?
        /* TODO: Investigate the possibility of `react-player` embedding Google Drive videos. */
        <iframe title={ name } className={styles.player} src={ embedUrl } frameBorder={0} allowFullScreen></iframe>
        :
        <ReactPlayer url={ embedUrl } controls={true} width="100%" height="100%"/>
      }
    </div>
  </HtmlContent>
)

export default VideoObject
