import React from 'react';
import ReactDOM from 'react-dom';
import { pdfjs } from 'react-pdf';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import IndexProvider from './IndexProvider';
import HomePage from './HomePage';
import ContentPage from './ContentPage';

import './index.css';
import "react-image-gallery/styles/css/image-gallery.css";

// configured `react-pdf` to use minified worker (https://www.npmjs.com/package/react-pdf)
pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

ReactDOM.render(
  <BrowserRouter>
    <IndexProvider>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/:page" element={<ContentPage/>} />
      </Routes>
    </IndexProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
