import React from 'react'
import { isEqual } from 'lodash'
import VisibilitySensor from 'react-visibility-sensor'
import links from './links'
import { useIndex } from './IndexProvider'
import Page from './Page'
import styles from './HomePage.module.css';


const PAGE_SIZE = 8
const PAGE_DELAY = 100

const HomePage = () => {

  const {filtered: index, query: indexQuery} = useIndex()

  const [query, setQuery] = React.useState('')

  const [list, setList] = React.useState([])

  const [isVisible, setIsVisible] = React.useState(true)

  React.useEffect(() => {
    if (query !== indexQuery) {
      setTimeout(() => setList([...index.slice(0, PAGE_SIZE)]), PAGE_DELAY)
      setQuery(indexQuery)
    } else if (!isEqual(list.map(item => item['nf:page']), index.map(item => item['nf:page'])) && isVisible) {
      setTimeout(() => setList([...index.slice(0, list.length + PAGE_SIZE)]), PAGE_DELAY)
    }
  }, [index, list, isVisible, query, indexQuery])

  return (
    <Page>
      <div className={styles.container}>
        {
          list.length === 0
          ?
          (
            indexQuery
            ?
            <h3>
              Nothing found for '{ query }'
            </h3>
            :
            null
          )
          :
          list.map(item => {
            const Template = links[item['nf:component']];
            return (
              <div key={item.name} className={styles.item}>
                <Template item={ item } />
              </div>
            )
          })
        }
        <div className={styles.item}>
          <VisibilitySensor intervalDelay={50} onChange={isVisible => setIsVisible(isVisible)}>
            <div>&nbsp;</div>
          </VisibilitySensor>
        </div>
      </div>
    </Page>
  )
}

export default HomePage
