import React from 'react';
import styles from './Paper.module.css';

const Paper = ({ className, children, ...otherProps }) => (
  <div className={`${styles.paper} ${className || ''}`} {...otherProps}>
    {children}
  </div>
)

export default Paper;
