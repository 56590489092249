import React, { useState } from 'react';
import { Link } from "react-router-dom";
import styles from './PortholeLink.module.css';

// based closely on this example: https://codepen.io/mackorichardson/pen/prJyq

export const getUrl = ({'nf:page': page}) => '/' + page;

const PORTHOLE_SIZE = 200;
const ZOOM_SIZE = 240;

const PortholeLink = ({ item }) => {

  const [imageWidth, setImageWidth] = useState(0);
  const [imagePositionStyle, setImagePositionStyle] = useState({});

  const onMouseEnter = () => setImagePositionStyle({
    height: ZOOM_SIZE,
    top: - (ZOOM_SIZE - PORTHOLE_SIZE) / 2, 
    left: - (imageWidth * ZOOM_SIZE / PORTHOLE_SIZE - PORTHOLE_SIZE) / 2,
  })

  const onMouseLeave = () => setImagePositionStyle({
    top: 0, left: - (imageWidth - PORTHOLE_SIZE) / 2
  })

  const onImgLoad = ({target: img}) => {
    setImageWidth(img.offsetWidth)
    setImagePositionStyle({top: 0, left: - (img.offsetWidth - PORTHOLE_SIZE) / 2})
  }

  return <>
    <div className={styles.wrapper}>
      <div className={styles.porthole}>
        <Link to={ getUrl(item) } onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} >
          { item.name }
        </Link>
        <img src={ item.image } alt={ item.name } onLoad={onImgLoad} style={imagePositionStyle} />
      </div>
    </div>
    <div className={styles.title}>
      <h3>
        { item.name }
      </h3>
    </div>
  </>
}

export default PortholeLink;
