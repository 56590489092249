import React, {useCallback} from 'react'
import { useNavigate } from "react-router-dom"
import { useWindowSize } from 'react-use'
import { useIndex } from './IndexProvider'
import styles from './Page.module.css';

export const calcNavOffset = width => (
  8 + Math.max(width - 1500, 0) / 2
)

const Page = ({children}) => {

  const navigate = useNavigate()

  const {query, setQuery} = useIndex()

  const handleChange = useCallback((value) => {
    setQuery(value)
  }, [setQuery])

  const handleSubmit = useCallback((event) => {
    event.preventDefault()
    if (query) {
      navigate('/')
    }
  }, [navigate, query])

  const { width } = useWindowSize()

  return (
    <div className={styles.page}>
      <div className={styles.navRight} style={{right: calcNavOffset(width)}}>
        <form className={styles.search} onSubmit={handleSubmit}>
          <input 
            type="text" placeholder="Search the content" name="search"
            onChange={event => handleChange(event.target.value)}
            value={query}
          />
          <button type="submit">
            <i className="material-icons">search</i>
          </button>
        </form>
      </div>
      {children}
    </div>
  )
}

export default Page
