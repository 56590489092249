import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, useParams } from "react-router-dom";
import { useWindowSize } from 'react-use'
import { useIndex } from './IndexProvider'
import * as pages from './pages';
import Page, { calcNavOffset } from './Page'
import styles from './ContentPage.module.css';

const ContentPage = () => {

  const { page } = useParams();

  const { index } = useIndex()

  const { width } = useWindowSize()

  let component = <></>

  if (index) {
    const metadata = index.find((metadata => metadata['nf:page'] === page))
    if (metadata) {
      component = <>
        <Helmet>
          <title>{ metadata.name }</title>
        </Helmet>
        { React.createElement(pages[metadata['nf:component']], {metadata}) }
      </>
    }
  }

  return (
    <Page>
      <div className={styles.navLeft} style={{left: calcNavOffset(width)}}>
        <Link to='/'>
          <i className="material-icons">home</i>
        </Link>
      </div>
      {component}
    </Page>
  )
}

export default ContentPage
