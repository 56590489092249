import * as React from 'react';
import {useSessionStorage} from 'react-use';

const filteredIndex = (index, query) => {
  if (query && index) {
    return index.filter(item => {
      const tags = item.keywords || [];
      const textToSearch = [item.name, item['@type'], ...tags];
      return textToSearch.find(
        text => text.toLowerCase().indexOf(query.toLowerCase()) !== -1
      )
    })
  }
  return index || [];
}

/**
 * React context for the current index.
 */
const IndexContext = React.createContext({});

/**
 * Index data provider.
 */
const IndexProvider = ({children}) => {

  const [query, setQuery] = React.useState('')
  const [index, setIndex] = React.useState([]);
  const [filtered, setFiltered] = React.useState([]);
  const [indexName, setIndexName] = useSessionStorage('indexName', null);

  React.useEffect(() => {
    if (index.length === 0) {
      let indexUrl = `/${indexName || 'index'}.json`
      // If the index has been given with a query param, then use it and save in the session.
      const searchParams = new URLSearchParams(window.location.search)
      if (searchParams.has('index')) {
        setIndexName(searchParams.get('index'))
        indexUrl = `/${searchParams.get('index')}.json`
      }

      fetch(indexUrl)
        .then(response => response.json())
        .then(setIndex)
        .catch(err => console.log(err))
    }
  }, [index, indexName, setIndexName]);

  React.useEffect(() => {
    setFiltered(filteredIndex(index, query))
  }, [index, query]);

  return <IndexContext.Provider value={{index, filtered, query, setQuery}}>
    { children }
  </IndexContext.Provider>;
};

export default IndexProvider;

export const useIndex = () => React.useContext(IndexContext)