import React from 'react';
import Paper from '../components/Paper';
import { withJsonContent as withContent } from '../withContent';
import styles from './Table.module.css';

const Table = ({ metadata: { name }, content }) => {

  let description = null
  let table = []

  if (content) {
    description = content.description
    table = content.table
  }
  
  return <div className={styles.container}>
    <h2 className={styles.title}>
      { name }
    </h2>
    {
      description
      ?
      <Paper className={styles.description}>
        { description.map(line => <p>{ line }</p>) }
      </Paper>
      :
      null
    }
    <Paper className={styles.wrapper}>
      <table className={styles.table}>
        <tbody>
          {table.map(row => {
            const cells = Array.isArray(row) ? row : [row];
            return (
              <tr key={cells[0]}>
                { cells.map(cell => <td key={cell}>{ cell }</td>) }
              </tr>
            )
          })}
        </tbody>
      </table>
    </Paper>
  </div>
}

export default withContent(Table)
