import React from 'react';

const withHtmlContent = WrappedComponent => ({ ...props }) => {

  const [content, setContent] = React.useState();

  React.useEffect(() => {
    if (!content) {
      fetch(`/pages${window.location.pathname}.html`)
        .then(response => response.text())
        .then(setContent)
        .catch(err => console.log(err))
    }
  }, [content]);

  return <WrappedComponent content={content} {...props} />
}

const withJsonContent = WrappedComponent => ({ ...props }) => {

  const [content, setContent] = React.useState();

  React.useEffect(() => {
    if (!content) {
      fetch(`/pages${window.location.pathname}.json`)
        .then(response => response.json())
        .then(setContent)
        .catch(err => console.log(err))
    }
  }, [content]);

  return <WrappedComponent content={content} {...props} />
}

export { withHtmlContent, withJsonContent };
