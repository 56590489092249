import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import styles from './TextDigitalDocument.module.css';

const TextDigitalDocument = ({ metadata: { "nf:document": document, name } }) => {

  const [numPages, setNumPages] = useState(1)

  let pages = [];
  for (var i = 1; i <= numPages; i++) {
    pages.push(<Page key={ i } className={ styles.docPage } pageNumber={ i } />);
  }

  return <>
    <h2 className={`${styles.layout} ${styles.alternative}`}>
      { name }
    </h2>
    <h4 className={`${styles.layout} ${styles.alternative}`}>
      Please download to view.
    </h4>
    <div className={styles.layout}>
      <Document file={ document } onLoadSuccess={({ numPages }) => setNumPages(numPages)} className={ styles.document } >
        { pages }
      </Document>
    </div>
    <div className={styles.layout}>
      <a className={ styles.button } href={ document } download>
        Download
      </a>
    </div>
  </>
}

export default TextDigitalDocument
