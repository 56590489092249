import React from 'react';
import ImageGallery from 'react-image-gallery';
import { withJsonContent as withContent } from '../withContent';
import styles from './ImageGallery.module.css';

const ImageGalleryComponent = ({ metadata: { name, "nf:page": page }, content }) => {

  const mapItem = item => {
    const file_name = item.photo.replace(/^.*[\\/]/, '');
    return {
      description: item.title,
      original: '/resources/' + page + '/full/' + file_name,
      thumbnail: '/resources/' + page + '/nail/' + file_name,
    }
  };

  return <>
      <h2 className={styles.title}>
        { name }
      </h2>
      <ImageGallery
        additionalClass={ styles.gallery }
        thumbnailPosition='top'
        items={(content && content.map(mapItem)) || []}
      />
  </>
}

export default withContent(ImageGalleryComponent);
