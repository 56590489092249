import React from 'react';
import styles from './Recipe.module.css';
import HtmlContent from './HtmlContent';

const Recipe = ({ metadata: { name, image } }) => (
  <HtmlContent name={name}>
    <img className={styles.image} src={ image } alt={ name } />
  </HtmlContent>
)

export default Recipe;
