import Link from './PortholeLink';

import * as pages from '../pages';

const links = {};

for (let page in pages) {
    links[page] = Link;
}

export default links;