import React from 'react';
import Paper from '../components/Paper';
import { withJsonContent as withContent } from '../withContent';
import styles from './Quiz.module.css';

const Question = ({ questionData, questionText, questionType }) => {

  if (questionType === 'image') {
    return (
      <div className={ styles.imageQuestion }>
        <Paper className={styles.imageBox}>
          <img className={styles.image} src={questionData.image} alt=''/>
        </Paper>
        <Paper className={`${styles.textBox} ${styles.imageQuestionText}`}>
          <p>{ questionText }</p>
        </Paper>
      </div>
    );
  }

  return (
    <Paper className={`${styles.textBox} ${styles.question}`}>
      <p>
        { questionText.replace('{}', questionData[questionType]) }
      </p>
    </Paper>
  );
}

const Choices = ({ questionList, choices, questionType, onSelect }) => {

  let choiceComponents = null;

  if (questionType === 'image') {
    choiceComponents = choices.map((choice, i) => (
      <Paper key={choice} className={`${styles.imageBox} ${styles.clickable}`} onClick={onSelect(i)}>
        <img className={styles.image} src={questionList[choice].image} alt=''/>
      </Paper>
    ))
  } else {
    choiceComponents = choices.map((choice, i) => (
      <Paper key={choice} className={`${styles.textBox} ${styles.clickable}`} onClick={onSelect(i)}>
        <p className={styles.text}>
          { questionList[choice][questionType] }
        </p>
      </Paper>
    ))
  };

  return <div className={ styles.choices } >
    { choiceComponents }
  </div>
}

const Answer = ({ correct, answerType, answerData, onNext }) => (
  <>
    {
      correct
      ?
      <i className={`material-icons ${styles.icon} ${styles.right}`}>check_circle</i>
      :
      <i className={`material-icons ${styles.icon} ${styles.wrong}`}>cancel</i>
    }
    <div className={styles.answer}>
      <Paper className={styles.imageBox}>
      {
        answerType === 'image'
        ?
        <img className={styles.image} src={answerData.image} alt=''/>
        :
        <p>
          { answerData[answerType] }
        </p>
      }
      </Paper>
    </div>
    <button className={styles.button} onClick={ onNext } >
      Next Question
    </button>
  </>
)

const Quiz = ({ content: questionList, metadata: { name, "nf:questions": questions } }) => {

  const [question, setQuestion] = React.useState({})
  const [selection, setSelection] = React.useState(null)
  const [questionsAsked, setQuestionsAsked] = React.useState(0)
  const [questionsRight, setQuestionsRight] = React.useState(0)

  const createQuestion = React.useCallback(() => {

    const type = Math.floor(Math.random() * questions.length);
    const choices = [];
    const answers = new Set();
    while (choices.length < 4) {
      const choice = Math.floor(Math.random() * questionList.length);
      const answer = questionList[choice][questions[type][2]]
      if (!answers.has(answer)) {
        answers.add(answer);
        choices.push(choice);
      }
    }
    return {
      type: type,
      choices: choices,
      answer: Math.floor(Math.random() * 4),
    }
  }, [questions, questionList]);

  const { type, choices, answer } = question;

  const handleSelect = (selection) => () => {
    setSelection(selection)
    setQuestionsRight(questionsRight + (selection === answer ? 1 : 0))
  };

  const handleNext = React.useCallback(() => {
    setQuestion(createQuestion())
    setSelection(null)
    setQuestionsAsked(questionsAsked + 1)
  }, [createQuestion, questionsAsked]);

  React.useEffect(() => {
    if (questionList && !choices) {
      handleNext()
    }
  }, [questionList, handleNext, choices]);

  const renderBody = () => {
    if (!questionList || !choices) {
      return <></>
    } else if (selection === null) {
      return <>
        <Question
          questionData={ questionList[choices[answer]] }
          questionText={ questions[type][0] }
          questionType={ questions[type][1] }
        />
        <div className={ styles.makeChoice } >
          <i className={`material-icons ${styles.icon}`}>arrow_downward</i>
        </div>
        <Choices
          questionList={ questionList }
          choices={ choices }
          questionType={ questions[type][2] }
          onSelect={ handleSelect }
        />
      </>
    }
    return <Answer
      correct={ selection === answer }
      answerType={ questions[type][2] }
      answerData={ questionList[choices[answer]] }
      onNext={ handleNext }
    />
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        { name }: ({ questionsRight }/{ questionsAsked })
      </h2>
      { renderBody() }
    </div>
  )
}

export default withContent(Quiz)
